import {
    AuthenticationsApi,
    AuthenticationsLoginRequest, AuthenticationsPutRequest,
    Configuration as ApiConfiguration,
    Middleware,
    TemporaryTokenAuthenticationResponseModel,
    TemporaryTokenAuthenticationsApi,
    TemporaryTokenAuthenticationsPostRequest,
    UserResponseModel,
    UsersApi,
    UsersGetByUserIdAsyncRequest
} from '@dealerpolicy/auth-function-client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { getAuthorizationHeaderValue } from '../libs/jwt/jwt-manager';
import User, { IUser } from '../models/user';
import { getConfiguration } from './config';

export interface IAuthenticationToken {
    token: string;
    expiresAt: number;
}

export interface IInitializeResponse {
    userId: string;
    organizationId: string | null;
    organizationIds: Array<string>;
}

export interface IAuthorizationData {
    decodedToken: IAuthenticationToken;
    data: IInitializeResponse;
}

const createApiConfiguration = (token?: string): ApiConfiguration => {
    const authorizationHeader = token ?? getAuthorizationHeaderValue();
    const configuration = getConfiguration();
    const basePath = configuration.AuthApiUri;
    const fetchApi = async (url: string, init: RequestInit) => fetch(url, { ...init });
    const middleware: Array<Middleware> = [];
    return new ApiConfiguration({
        basePath: basePath,
        apiKey: authorizationHeader,
        fetchApi: fetchApi,
        middleware: middleware
    });
};

export const refreshToken = async (token: string) => {
    const config = createApiConfiguration(token);
    const authApi = new AuthenticationsApi(config);
    const request: AuthenticationsPutRequest = {
        setCookie: false
    };

    try {
        const response = await authApi.authenticationsPut(request);
        if (!response.authorization) {
            throw new Error('Unable to refresh token');
        }
        return decodeToken(response.authorization);
    } catch (e) {
        throw new Error('Unable to refresh token');
    }
};

export const decodeToken = (token: string): IAuthenticationToken => {
    const decodedToken = jwtDecode<JwtPayload>(token);

    const exp = decodedToken.exp;
    if (!exp) {
        throw new Error('Session Expired, please log in.');
    }
    const currentTime = new Date().getTime();
    const expiresAt = new Date().setTime(exp * 1000);
    const diff = expiresAt - currentTime;

    if (diff < 0) {
        throw new Error('Session Expired, please log in.');
    }

    return {
        token: token,
        expiresAt: expiresAt
    } as IAuthenticationToken;
};

export const loginAsync = async (username: string, password: string, organizationId?: string): Promise<IAuthorizationData> => {
    const config = createApiConfiguration();
    const instance = new AuthenticationsApi(config);
    const request: AuthenticationsLoginRequest = {
        loginRequestModel: {
            username,
            password
        }
    };

    try {
        const response = await instance.authenticationsLogin(request);
        if (!response.id || !response.authorization) {
            throw new Error('Invalid username or password');
        }
        if (!response.dealershipIds || (response.dealershipIds || []).length === 0) {
            throw new Error('Required permissions not met');
        }
        if (organizationId && !response.dealershipIds.includes(organizationId)) {
            throw new Error('Required permissions not met');
        }
        return {
            decodedToken: decodeToken(response.authorization),
            data: {
                userId: response.id,
                organizationId: organizationId ?? (response.dealershipIds.length === 1 ? response.dealershipIds[0] : null),
                organizationIds: response.dealershipIds ?? []
            }
        };
    } catch (error) {
        throw error instanceof Error ? error : new Error('Invalid username or password');
    }
};

export const postTemporaryTokenAuthenticationsAsync = async (token: string): Promise<TemporaryTokenAuthenticationResponseModel> => {
    const config = createApiConfiguration();
    const instance = new TemporaryTokenAuthenticationsApi(config);

    const request: TemporaryTokenAuthenticationsPostRequest = {
        temporaryTokenAuthenticationRequestModel: {
            token: token
        }
    };

    return instance.temporaryTokenAuthenticationsPost(request);
};

export const getUserAsync = async (userId: string): Promise<IUser> => {
    const config = createApiConfiguration();
    const instance = new UsersApi(config);
    const request: UsersGetByUserIdAsyncRequest = {
        userid: userId
    };

    const response: UserResponseModel = await instance.usersGetByUserIdAsync(request);

    return User.create({
        id: response.id,
        username: response.username,
        firstName: response.firstName,
        lastName: response.lastName,
        roles: response.roles,
        dealershipIds: response.dealershipIds
    });
};
