import * as R from 'ramda';
import Logger from '../libs/logger';
import { inDevBuildEnv } from '../libs/utilities';
import Configuration, { IConfiguration } from '../models/configuration/Configuration';

let configuration: IConfiguration | null = null;

/**
 * fetch the config data from the data source
 * @returns {Promise<null|any>} -
 */
const fetchConfig = async (): Promise<IConfiguration> => {
    if (inDevBuildEnv()) {
        const configFile = await import('../config/config.json');
        const developmentConfigFile = await import('../config/config.Development.json');
        return R.mergeDeepRight(configFile, developmentConfigFile) as unknown as IConfiguration;
    }

    const endpoint = '/config.json';
    const settings: RequestInit = {
        cache: 'no-cache',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
        redirect: 'follow',
        referrer: 'no-referrer'
    };

    const response = await fetch(endpoint, settings);
    return response.json() as unknown as IConfiguration;
};

/**
 * returns the configuration object for the current environment
 */
export const getConfigurationAsync = async (): Promise<IConfiguration> => {
    if (configuration !== null) {
        return configuration;
    }

    try {
        const data = await fetchConfig();
        configuration = Configuration.create(data);
    } catch (error) {
        Logger.logCritical('Error fetching configuration.', error);
        configuration = Configuration.create();
    } finally {
        Logger.logInformation('Configuration', configuration);
    }

    return configuration;
};

export const getConfiguration = (): IConfiguration => {
    if (configuration === null) {
        const error = Error('Configuration has either not been fetch or there was a problem fetching it.');
        Logger.logWarning(error.message, error);
        return Configuration.create();
    }
    return configuration;
};
