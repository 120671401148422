import { Dialog, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { nameof } from '../../libs/utilities/TypeScriptCookbook';
import { IDealership } from '../../models/dealership';

interface IOrganizationFormProps {
    id: string | null;
}

type FormModel = IOrganizationFormProps;

interface ISelectOrganizationDialogProps {
    options: Array<IDealership>;
    isOpen: boolean;
    onClose: () => void;
    onSelect: (organizationId: string) => void;
}

const SelectOrganizationDialog: React.FC<ISelectOrganizationDialogProps> = ({ options, isOpen, onClose, onSelect }) => {
    const formMethods = useForm<IOrganizationFormProps>({
        defaultValues: {
            id: null
        }
    });

    return (
        <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={onClose}
            open={isOpen}
        >
            <DialogTitle>
                {'Select the dealership you are working in'}
            </DialogTitle>
            <DialogContent>
                <Controller
                    as={(
                        <TextField
                            SelectProps={{
                                displayEmpty: true
                            }}
                            error={Boolean(formMethods.errors?.id?.message)}
                            fullWidth={true}
                            helperText={formMethods.errors?.id?.message}
                            inputProps={{
                                'aria-label': 'id',
                                id: nameof<FormModel>('id')
                            }}
                            label={'Dealership'}
                            onClick={formMethods.handleSubmit((data) => onSelect(data.id ?? ''))}
                            select={true}
                            style={{ textAlign: 'left' }}
                            variant={'outlined'}
                        >
                            {
                                (options ?? []).map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    )}
                    control={formMethods.control}
                    defaultValue={''}
                    name={nameof<IOrganizationFormProps>('id')}
                    rules={{
                        required: 'Please select your dealership'
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SelectOrganizationDialog;
