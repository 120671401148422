import {
    Configuration as ApiConfiguration,
    PowerBIEmbedTokensApi,
    Middleware,
    PowerBIEmbedTokensGetByIdRequest,
    PowerBIEmbedTokenReportInformation, PowerBIReportApi, PowerBIReportsResponseModel
} from '@dealerpolicy/reports-function-client';
import { getAuthorizationHeaderValue } from '../libs/jwt/jwt-manager';
import Logger from '../libs/logger';
import { getConfigurationAsync } from './config';

const createApiConfiguration = async (token?: string): Promise<ApiConfiguration> => {
    const authorizationHeader = token ?? getAuthorizationHeaderValue();
    const configuration = await getConfigurationAsync();
    const basePath = configuration.ReportsApiUri;
    const fetchApi = async (url: string, init: RequestInit) => fetch(url, { ...init });
    const middleware: Array<Middleware> = [];
    return new ApiConfiguration({
        basePath: basePath,
        apiKey: authorizationHeader,
        fetchApi: fetchApi,
        middleware: middleware
    });
};

export const getPowerBIReportsAsync = async (authToken: string): Promise<PowerBIReportsResponseModel> => {
    const config = await createApiConfiguration(authToken);
    const instance = new PowerBIReportApi(config);

    return instance.powerBIReportGetReportsAsync();
};

export const getPowerBIReportByOrganizationIdAndReportIdAsync = async (organizationId: string, internalReportId: string, authToken: string): Promise<{token: string; workspaceId: string; reportIds: Array<string>}> => {
    const config = await createApiConfiguration(authToken);
    const instance = new PowerBIEmbedTokensApi(config);
    const request: PowerBIEmbedTokensGetByIdRequest = {
        internalReportId: internalReportId,
        organizationId: organizationId
    };

    try {
        const response = await instance.powerBIEmbedTokensGetById(request);
        if (!response.token) {
            const errorMessage = 'Embed token response did not contain token.';
            Logger.logCritical(errorMessage);
            throw new Error(errorMessage);
        }
        if (!response.workspaceId) {
            const errorMessage = 'Embed token response did not contain workspace.';
            Logger.logCritical(errorMessage);
            throw new Error(errorMessage);
        }
        if (!response.reports) {
            const errorMessage = 'Embed token response did not contain reports.';
            Logger.logCritical(errorMessage);
            throw new Error(errorMessage);
        }
        return {
            workspaceId: response.workspaceId,
            reportIds: response.reports.map((x: PowerBIEmbedTokenReportInformation) => x.reportId ?? ''),
            token: response.token
        };
    } catch (clientError) {
        const errorMessage = clientError.status === 403 ? 'Insufficient privileges to view reports' : 'Failed to generate embed token.';
        Logger.logCritical(errorMessage, clientError);
        throw new Error(errorMessage);
    }
};
