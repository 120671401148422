import { PowerBIReportResponseModel } from '@dealerpolicy/reports-function-client';

export type IReport = {
    isAuthed: boolean;
    selectedEmbedConfiguration: any;
    reports: Array<PowerBIReportResponseModel>;
};

const create = (args: Partial<IReport> = {}): IReport => ({
    isAuthed: args.isAuthed ?? false,
    selectedEmbedConfiguration: args.selectedEmbedConfiguration ?? null,
    reports: args.reports ?? []
});

const Report = {
    create: create
};

export default Report;
