import { createModelSlice, IModelState, StatusEnum } from '@gjv/redux-slice-factory';
import { createSelector } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import { DEALERSHIP_ID, USER_ID } from '../../constants/terms/storage-keys';
import {
    decodeToken,
    getUserAsync,
    IAuthorizationData,
    loginAsync,
    postTemporaryTokenAuthenticationsAsync,
    refreshToken
} from '../../data-sources/authentication-api';
import { getDealershipsByUserAsync } from '../../data-sources/marketplace-api';
import { setAuthorizationHeaderValue } from '../../libs/jwt/jwt-manager';
import Authentication, { IAuthentication } from '../../models/authentication';
import Dealership, { IDealership } from '../../models/dealership';
import { AppState } from '../../store/configureStore';
import { IDuck } from '../types';

export type IAuthenticationModelState = IModelState<IAuthentication>;

const slice = createModelSlice<AppState, IAuthentication>({
    name: 'Authentication',
    selectSliceState: (appState) => appState.Authentication,
    initialState: {
        model: Authentication.create()
    }
});

const authenticateWithTemporaryToken = (token: string, dealershipId?: string) => async (dispatch: Dispatch): Promise<void> => {
    batch(() => {
        dispatch(slice.actions.setStatus(StatusEnum.Requesting));
        dispatch(slice.actions.setError(null));
    });

    try {
        const result = await postTemporaryTokenAuthenticationsAsync(token);
        const authToken = decodeToken(result?.authorization ?? '');
        setAuthorizationHeaderValue(authToken.token);
        const user = await getUserAsync(result?.userId ?? '');
        const organizations = await getUserOrganizations(user?.id ?? '');
        batch(() => {
            dispatch(slice.actions.hydrate({
                isAuthed: true,
                token: authToken,
                dealershipId: dealershipId && filterOrganizations(organizations).find((validOrganization) => validOrganization.id === dealershipId)
                    ? dealershipId
                    : null,
                dealershipIds: user.dealershipIds,
                dealerships: organizations,
                userId: result?.userId ?? '',
                username: null
            }));
            dispatch(slice.actions.setStatus(StatusEnum.Settled));
            dispatch(slice.actions.setError(null));
        });
    } catch (error) {
        batch(() => {
            dispatch(slice.actions.setStatus(StatusEnum.Failed));
            dispatch(slice.actions.setError(new Error('Failed pass-through authentication')));
        });
    }
};

const login = (username: string, password: string, organizationId?: string) => async (dispatch: Dispatch): Promise<void> => {
    batch(() => {
        dispatch(slice.actions.setStatus(StatusEnum.Requesting));
        dispatch(slice.actions.setError(null));
    });

    try {
        const result = await loginAsync(username, password, organizationId);
        await hydrateLoginData(username, result, dispatch);
    } catch (error) {
        batch(() => {
            dispatch(slice.actions.setStatus(StatusEnum.Failed));
            dispatch(slice.actions.setError(error));
        });
    }
};

const hydrateLoginData = async (username: string, result: IAuthorizationData, dispatch: Dispatch): Promise<void> => {
    setAuthorizationHeaderValue(result.decodedToken.token);

    const organizations = await getUserOrganizations(result.data.userId);
    const validOrganizations = filterOrganizations(organizations);
    if (validOrganizations.length > 0) {
        batch(() => {
            dispatch(slice.actions.hydrate({
                isAuthed: false,
                token: result.decodedToken,
                dealershipId: validOrganizations.length > 1 ? null : result.data.organizationId ?? validOrganizations[0].id,
                dealershipIds: result.data.organizationIds,
                dealerships: organizations,
                userId: result.data.userId,
                username: username
            }));
            dispatch(slice.actions.setStatus(StatusEnum.Settled));
            dispatch(slice.actions.setError(null));
        });

        window.sessionStorage.setItem(USER_ID, result.data.userId);
        window.sessionStorage.setItem(DEALERSHIP_ID, result.data.organizationId ?? '');
    } else {
        batch(() => {
            dispatch(slice.actions.setStatus(StatusEnum.Failed));
            dispatch(slice.actions.setError(new Error('Organization not found')));
        });
    }
};

const getUserOrganizations = async (userId: string): Promise<Array<IDealership>> => {
    const dealerships = await getDealershipsByUserAsync(userId);

    return dealerships.map((dealership) => Dealership.create({
        id: dealership.id,
        name: dealership.name,
        isDeleted: dealership.isDeleted
    }));
};

const reset = () => (dispatch: Dispatch): void => {
    dispatch(slice.actions.reset());

    window.sessionStorage.setItem(USER_ID, '');
    window.sessionStorage.setItem(DEALERSHIP_ID, '');
};

const refresh = (model: IAuthentication) => async (dispatch: Dispatch): Promise<void> => {
    batch(() => {
        dispatch(slice.actions.setStatus(StatusEnum.Requesting));
        dispatch(slice.actions.setError(null));
    });
    try {
        const result = await refreshToken(model.token?.token ?? '');
        batch(() => {
            dispatch(slice.actions.hydrate({
                isAuthed: true,
                token: result,
                dealershipId: model.dealershipId,
                dealershipIds: model.dealershipIds,
                dealerships: model.dealerships,
                userId: model.userId,
                username: model.username
            }));
            dispatch(slice.actions.setStatus(StatusEnum.Settled));
            dispatch(slice.actions.setError(null));
        });
    } catch (error) {
        batch(() => {
            dispatch(slice.actions.setStatus(StatusEnum.Failed));
            dispatch(slice.actions.setError(error));
        });
    }
};

const setAuthenticationHeader = (model: IAuthentication, token?: string) => (dispatch: Dispatch): void => {
    dispatch(slice.actions.setStatus(StatusEnum.Requesting));
    setAuthorizationHeaderValue(token);
    dispatch(slice.actions.update({
        ...model,
        isAuthed: true
    }));
    dispatch(slice.actions.setStatus(StatusEnum.Settled));
};

const changeOrganizationId = (model: IAuthentication) => (dispatch: Dispatch): void => {
    dispatch(slice.actions.setStatus(StatusEnum.Requesting));
    dispatch(slice.actions.update({
        ...model
    }));
    window.sessionStorage.setItem(DEALERSHIP_ID, model.dealershipId ?? '');
    dispatch(slice.actions.setStatus(StatusEnum.Settled));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const selectOrganization = () => createSelector(
    slice.selectors.selectModel,
    (model) => model?.dealerships.find((dealership) => dealership.id === model?.dealershipId) ?? {} as IDealership
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const selectValidOrganizations = () => createSelector(
    slice.selectors.selectModel,
    (model) => filterOrganizations(model?.dealerships ?? [])
);

const filterOrganizations = (organizations: Array<IDealership>) => organizations.filter((organization) => !organization.isDeleted);

const allActions = {
    ...slice.actions,
    authenticateWithTemporaryToken: authenticateWithTemporaryToken,
    changeOrganizationId: changeOrganizationId,
    login: login,
    refresh: refresh,
    reset: reset,
    setAuthenticationHeader: setAuthenticationHeader
};

const allSelectors = {
    ...slice.selectors,
    selectOrganization: selectOrganization,
    selectValidOrganizations: selectValidOrganizations
};

const AuthenticationDuck: IDuck<IAuthenticationModelState, typeof allActions, typeof allSelectors> = {
    Name: slice.name,
    Reducer: slice.reducer,
    Actions: allActions,
    Selectors: allSelectors
};

export default AuthenticationDuck;
