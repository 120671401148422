import { ContentLayout } from '@dealerpolicy/react';
import { Box, Button, Container, GridJustification, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PollyLogo from '../../assets/logos/PollyLogo.svg';
import { AuthenticationDuck } from '../../features/authentication';
import { ConfigurationDuck } from '../../features/configuration';
import { ReportDuck } from '../../features/report';
import Authentication from '../../models/authentication';
import { IDPTheme } from '../../themes/types';

const useStyles = makeStyles((theme: IDPTheme) => ({
    mainBackgroundColor: {
        backgroundColor: theme.palette.background.default
    },
    screenPaddingTop: {
        paddingTop: '1vh'
    },
    footerContent: (props: any) => ({
        minHeight: `calc(100vh - ${theme.spacing(20)}px)`,
        [theme.breakpoints.up('xs')]: {
            minHeight: `calc(100vh - ${theme.spacing(24)}px)`
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: `calc(100vh - ${theme.spacing(27)}px)`
        },
        [theme.breakpoints.up('md')]: {
            minHeight: `calc(100vh - ${theme.spacing(29)}px)`
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: `calc(100vh - ${theme.spacing(31)}px)`
        },
        [theme.breakpoints.up('xl')]: {
            minHeight: `calc(100vh - ${theme.spacing(33)}px)`
        },
        paddingBottom: theme.spacing(props.addPaddingBottom ? 4 : 0)
    }),
    logoContainer: {
        position: 'absolute',
        left: '50%',
        top: '55%',
        transform: 'translate(-50%, -50%)'
    },
    mainContent: {
        overflow: 'hidden'
    },
    pageContainer: {
        position: 'relative',
        minHeight: '100vh'
    },
    containerHeader: {
        padding: 0
    },
    containerRoot: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    copyright: {
        color: theme.palette.grey['600'],
        bottom: theme.spacing(2),
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%'
    },
    toolbar: {
        minHeight: '7vh',
        backgroundColor: '#141414'
    },
    typography: {
        color: 'white'
    },
    dpLogo: {
        padding: '0',
        height: '100%'
    }
}));

const onReturn = (returnUri: string) => {
    window.location.href = returnUri;
};

interface IScreenLayoutProps {
    backgroundClassName?: string | null;
    disableGutter?: boolean;
    containerMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    justify?: GridJustification;
    containerRootClassname?: string;
}

const ScreenLayout = ({
    children,
    backgroundClassName,
    justify = 'space-between',
    containerRootClassname
}: React.PropsWithChildren<IScreenLayoutProps>) => {
    const dispatch = useDispatch();
    const classes = useStyles({});

    const authentication = useSelector(AuthenticationDuck.Selectors.selectModel);
    const configurationModelState = useSelector(ConfigurationDuck.Selectors.selectSliceState);
    const returnToMarketplaceUri = configurationModelState?.model?.ReturnToMarketplaceUri;

    return (
        <div className={classes.pageContainer}>
            <Container
                className={classes.containerHeader}
                maxWidth={false}
            >
                <Box boxShadow={2}>
                    <Toolbar className={classes.toolbar}>
                        {
                            returnToMarketplaceUri
                                ? (
                                    <Typography
                                        align={'left'}
                                        className={classes.typography}
                                    >
                                        <Button
                                            className={classes.typography}
                                            onClick={() => {
                                                batch(() => {
                                                    // this resets, without kicking the user back to the login page
                                                    dispatch(AuthenticationDuck.Actions.update(Authentication.create({
                                                        isAuthed: true
                                                    })));
                                                    dispatch(ReportDuck.Actions.reset());
                                                });

                                                onReturn(returnToMarketplaceUri);
                                            }}
                                        >
                                            {'< RETURN TO POLLY FOR DEALERS'}
                                        </Button>
                                    </Typography>
                                )
                                : null
                        }
                        <div className={classes.logoContainer}>
                            <img
                                alt={'PollyLogo'}
                                className={classes.dpLogo}
                                src={PollyLogo}
                            />
                        </div>
                        {
                            authentication.isAuthed && returnToMarketplaceUri === null
                                ? (
                                    <Typography
                                        align={'left'}
                                        className={classes.typography}
                                    >
                                        <Button
                                            className={classes.typography}
                                            onClick={() => dispatch(AuthenticationDuck.Actions.reset())}
                                        >
                                            {'LOG OUT'}
                                        </Button>
                                    </Typography>
                                )
                                : null
                        }
                    </Toolbar>
                </Box>
            </Container>
            <main
                className={clsx(
                    backgroundClassName || classes.mainBackgroundColor,
                    classes.mainContent
                )}
                id={'mainContent'}
            >
                <div className={clsx(classes.screenPaddingTop)}>
                    <Container
                        classes={{ root: containerRootClassname ?? classes.containerRoot }}
                        maxWidth={false}
                    >
                        <ContentLayout
                            containerClassName={classes.footerContent}
                            direction={'column'}
                            justify={justify}
                            textAlign={'left'}
                        >
                            {children}
                        </ContentLayout>
                    </Container>
                </div>
            </main>
            <Typography
                align={'center'}
                className={classes.copyright}
                component={'p'}
                variant={'caption'}
            >
                {`© ${new Date().getFullYear()} DealerPolicy®, Inc.`}
            </Typography>
        </div>
    );
};

export default ScreenLayout;
