import {
    Configuration as ApiConfiguration,
    DealershipModel,
    Middleware,
    UsersApi,
    UsersGetDealershipsAsyncRequest
} from '@dealerpolicy/marketplace-function-client';
import { getAuthorizationHeaderValue } from '../libs/jwt/jwt-manager';
import { getConfiguration } from './config';

const createApiConfiguration = (): ApiConfiguration => {
    const authorizationHeader = getAuthorizationHeaderValue();
    const config = getConfiguration();
    const basePath = config.MarketplaceApiUri;
    const fetchApi = async (url: string, init: RequestInit) => fetch(url, { ...init });
    const middleware: Array<Middleware> = [];
    return new ApiConfiguration({
        basePath: basePath,
        apiKey: authorizationHeader,
        fetchApi: fetchApi,
        middleware: middleware
    });
};

export const getDealershipsByUserAsync = async (userId: string): Promise<Array<DealershipModel>> => {
    const configuration = createApiConfiguration();
    const instance = new UsersApi(configuration);
    const request: UsersGetDealershipsAsyncRequest = {
        userid: userId
    };

    return instance.usersGetDealershipsAsync(request);
};
