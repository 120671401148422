import { ContentLayout } from '@dealerpolicy/react';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ScreenLayout from '../../layouts/screen-layout';

const NotFound = () => (
    <ScreenLayout>
        <ContentLayout
            enableBreakpointSpacing={true}
            spacing={3}
        >
            <Typography
                variant={'body1'}
            >
                {'Page not found'}
            </Typography>
            <Typography
                variant={'body1'}
            >
                {'We couldn\'t find the page you were looking for.'}
            </Typography>
        </ContentLayout>
        {' '}

    </ScreenLayout>
);

export default NotFound;
export const NotFoundPath = '/not-found';
