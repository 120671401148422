import { ContentLayout } from '@dealerpolicy/react';
import { StatusEnum } from '@gjv/redux-slice-factory';
import { InputAdornment, IconButton, Container, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import AlertError from '../../components/alert-error';
import FormButton from '../../components/form-button';
import Spinner from '../../components/spinner';
import * as AUTOCOMPLETE_TOKENS from '../../constants/autocomplete-tokens';
import SelectOrganizationDialog from '../../dialogs/select-organization-dialog/SelectOrganizationDialog';
import SelectReportDialog from '../../dialogs/select-report-dialog';
import { AuthenticationDuck } from '../../features/authentication';
import { ConfigurationDuck } from '../../features/configuration';
import { ReportDuck } from '../../features/report';
import useNavigation from '../../hooks/use-navigation';
import useQuery from '../../hooks/use-query';
import ScreenLayout from '../../layouts/screen-layout';
import { nameof } from '../../libs/utilities/TypeScriptCookbook';
import { isValidEmailAddress } from '../../libs/validation-rules';
import Authentication from '../../models/authentication';
import { IDPTheme } from '../../themes/types';
import { ReportScreenPath } from '../report-screen';

const useStyles = makeStyles((theme: IDPTheme) => ({
    container: {
        paddingTop: '1vh',
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(4)
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(9)
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(11)
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(13)
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(15)
        }
    },
    label: {
        padding: theme.spacing(0.5, 0),
        textAlign: 'left'
    },
    dpLogo: {
        [theme.breakpoints.up('xs')]: {
            width: 240
        },
        [theme.breakpoints.up('sm')]: {
            width: 300
        }
    },
    toolbar: {
        minHeight: '7vh',
        backgroundColor: '#f5f5f5',
        border: '1px solid #cccccc'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    }
}));

interface ILoginFormProps {
    username: string;
    password: string;
    organizationId?: string;
}

type FormModel = ILoginFormProps;

const LoginScreen = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { navigateTo } = useNavigation();
    const parameters = useQuery<{
        dealershipid?: string;
        returnuri?: string;
        token?: string;
    }>();

    const authenticationModelState = useSelector(AuthenticationDuck.Selectors.selectSliceState);
    const configurationModelState = useSelector(ConfigurationDuck.Selectors.selectSliceState);
    const organizations = useSelector(AuthenticationDuck.Selectors.selectValidOrganizations());
    const reportModelState = useSelector(ReportDuck.Selectors.selectSliceState);

    const formMethods = useForm<FormModel>({
        mode: 'onTouched',
        defaultValues: {
            username: authenticationModelState.model?.username ?? '',
            password: ''
        }
    });

    const { username, password } = formMethods.watch();

    const [justPassingThru, setJustPassingThru] = useState(false);
    const [dealershipSelectOpen, setDealershipSelectOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [reportSelectOpen, setReportSelectOpen] = useState(false);
    const [hasFetchedReports, setHasFetchedReports] = useState(false);
    const [hasHydratedConfiguration, setHasHydratedConfiguration] = useState(false);

    const isLoading = authenticationModelState.status === StatusEnum.Requesting || reportModelState.status === StatusEnum.Requesting;

    const onSubmit = useCallback((data: FormModel) => {
        batch(() => {
            dispatch(AuthenticationDuck.Actions.reset());
            dispatch(AuthenticationDuck.Actions.login(data.username, data.password, data.organizationId));
        });
        setShowError(false);
    }, [dispatch]);

    useEffect(() => {
        if (parameters?.token
            && !isLoading
            && configurationModelState.lastHydrated !== null
            && !authenticationModelState.error
            && !authenticationModelState.model.isAuthed) {
            setJustPassingThru(true);
            dispatch(AuthenticationDuck.Actions.authenticateWithTemporaryToken(parameters?.token, parameters?.dealershipid));
            setShowError(false);
        }
    }, [authenticationModelState.error, authenticationModelState.model.isAuthed, configurationModelState.lastHydrated, dispatch, isLoading, parameters]);

    useEffect(() => {
        if (parameters?.returnuri) {
            dispatch(ConfigurationDuck.Actions.updateMarketplaceReturnUri(decodeURIComponent(parameters?.returnuri)));
        }
    }, [dispatch, parameters]);

    // show the organization selection dialog
    useEffect(() => {
        if (authenticationModelState.model?.isAuthed
            && !authenticationModelState.error
            && authenticationModelState.model?.dealershipId === null
            && (organizations ?? []).length > 1) {
            setDealershipSelectOpen(true);
        }
    }, [authenticationModelState.error, authenticationModelState.model, dispatch, organizations, parameters]);

    useEffect(() => {
        if (reportModelState.model.isAuthed) {
            navigateTo(ReportScreenPath);
        }
    }, [navigateTo, reportModelState.model.isAuthed]);

    useEffect(() => {
        if (authenticationModelState.error?.message && !isLoading && !showError) {
            setShowError(true);
            if (justPassingThru) {
                setJustPassingThru(false);
                navigateTo(LoginScreenPath); // reset any query string parameters
            }
        }
    }, [authenticationModelState.error, isLoading, justPassingThru, navigateTo, showError]);

    useEffect(() => {
        if (reportModelState.error?.message && !isLoading && !showError) {
            setJustPassingThru(false);
            setShowError(true);
            setDealershipSelectOpen(false);
        }
    }, [isLoading, reportModelState.error, showError]);

    useEffect(() => {
        if (organizations
            && organizations.length >= 1
            && !hasFetchedReports
            && !isLoading
            && authenticationModelState.model.dealershipId
            && authenticationModelState.model.token
            && !reportModelState.error
            && !reportModelState.model.isAuthed) {
            setHasFetchedReports(true);
            dispatch(ReportDuck.Actions.fetchReports(authenticationModelState.model.token.token));
        }
    }, [authenticationModelState.model, configurationModelState.model.PowerBIEmbedUri, dispatch, hasFetchedReports, isLoading, organizations, reportModelState.error, reportModelState.model.isAuthed]);

    useEffect(() => {
        if (reportModelState?.model?.reports?.length === 1 && !isLoading && !reportModelState.model.isAuthed && !hasHydratedConfiguration) {
            setHasHydratedConfiguration(true);
            const report = reportModelState.model.reports[0];
            const reportId = report.reportId ?? '';
            dispatch(ReportDuck.Actions.hydrateSelectedEmbeddedConfiguration(report.id ?? '', reportId, configurationModelState.model.PowerBIEmbedUri, authenticationModelState.model.token?.token ?? '', authenticationModelState.model.dealershipId ?? ''));
        }
    }, [authenticationModelState, configurationModelState, dispatch, reportModelState, isLoading, hasHydratedConfiguration]);

    useEffect(() => {
        if (reportModelState?.model?.reports?.length > 1) {
            setReportSelectOpen(true);
        }
    }, [reportModelState]);

    return (
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <ScreenLayout>
                <Container
                    className={classes.container}
                    maxWidth={'xs'}
                >
                    {
                        justPassingThru
                            ? <Spinner message={'Attempting pass-through authentication'} />
                            : (
                                <ContentLayout
                                    direction={'column'}
                                    spacing={2}
                                    textAlign={'center'}
                                >
                                    <Typography variant={'h2'}>
                                        {'Sign in to View Reports'}
                                    </Typography>
                                    <TextField
                                        autoComplete={AUTOCOMPLETE_TOKENS.EMAIL}
                                        disabled={isLoading}
                                        error={Boolean(formMethods.errors.username?.message)}
                                        fullWidth={true}
                                        helperText={formMethods.errors.username?.message}
                                        id={'username'}
                                        inputRef={formMethods.register({
                                            required: 'Please enter a valid username',
                                            validate: (data) => isValidEmailAddress(data) || 'Please enter a valid username'
                                        })}
                                        label={'Username'}
                                        name={nameof<ILoginFormProps>('username')}
                                        onBlur={() => formMethods.setValue(nameof<ILoginFormProps>('username'), username.trim(), { shouldValidate: true })}
                                        placeholder={'Username'}
                                        variant={'outlined'}
                                    />
                                    <TextField
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position={'end'}>
                                                    <IconButton
                                                        aria-label={'toggle password visibility'}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={(event) => {
                                                            event.preventDefault();
                                                        }}
                                                        size={'small'}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        autoComplete={AUTOCOMPLETE_TOKENS.CURRENT_PASSWORD}
                                        disabled={isLoading}
                                        error={Boolean(formMethods.errors.password?.message)}
                                        fullWidth={true}
                                        helperText={formMethods.errors.password?.message}
                                        id={'password'}
                                        inputRef={formMethods.register()}
                                        label={'Password'}
                                        name={nameof<ILoginFormProps>('password')}
                                        onBlur={() => formMethods.setValue(nameof<ILoginFormProps>('password'), password.trim(), { shouldValidate: true })}
                                        placeholder={'Password'}
                                        type={showPassword ? 'text' : 'password'}
                                        variant={'outlined'}
                                    />
                                    <FormButton
                                        isSubmitting={isLoading}
                                        type={'submit'}
                                        variant={'contained'}
                                    >
                                        {'Sign In'}
                                    </FormButton>
                                    <AlertError show={showError}>
                                        {authenticationModelState.error?.message ?? reportModelState.error?.message ?? 'Invalid login'}
                                    </AlertError>
                                </ContentLayout>
                            )
                    }
                </Container>
                <SelectOrganizationDialog
                    isOpen={dealershipSelectOpen && !isLoading}
                    onClose={() => setDealershipSelectOpen(false)}
                    onSelect={(dealershipId) => {
                        setShowError(false);
                        dispatch(AuthenticationDuck.Actions.changeOrganizationId(Authentication.create({
                            ...authenticationModelState.model,
                            dealershipId: dealershipId
                        })));
                    }}
                    options={organizations}
                />
                <SelectReportDialog
                    isOpen={reportSelectOpen && !isLoading}
                    onClose={() => setReportSelectOpen(false)}
                    onSelect={(internalReportId) => {
                        const reportId = reportModelState.model.reports.find((report) => report.id === internalReportId)?.reportId ?? '';
                        dispatch(ReportDuck.Actions.hydrateSelectedEmbeddedConfiguration(internalReportId, reportId, configurationModelState.model.PowerBIEmbedUri, authenticationModelState.model.token?.token ?? '', authenticationModelState.model.dealershipId ?? ''));
                    }}
                    options={reportModelState.model.reports}
                />
            </ScreenLayout>
        </form>
    );
};

export default LoginScreen;

export const LoginScreenPath = '/login';
