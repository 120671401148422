/* global window */

import { JWTToken } from '../../constants/terms/storage-keys';

export function setAuthorizationHeaderValue(authorizationHeader?: string): void {
    if (authorizationHeader) {
        window.sessionStorage.setItem(JWTToken, authorizationHeader);
    }
}

export function getAuthorizationHeaderValue(): string | undefined {
    const storedValue = window.sessionStorage.getItem(JWTToken);
    // eslint-disable-next-line no-undefined
    return storedValue === null ? undefined : storedValue;
}

export function clearAuthorizationHeaderValue() {
    window.sessionStorage.removeItem(JWTToken);
}
