import { Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { IDPTheme } from '../../themes/types';

const useStyles = makeStyles((theme: IDPTheme) => ({
    root: {
        '& .MuiAlert-icon': {
            alignItems: 'center',
            color: theme.palette.primary.main
        },
        '& .MuiAlert-message': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
            whiteSpace: 'pre-wrap'
        },
        backgroundColor: theme.palette.common.white,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(0, 1.5),
        textAlign: 'left',
        width: '100%'
    },
    icon: {
        alignItems: 'center',
        color: theme.palette.primary.main
    },
    message: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        whiteSpace: 'pre-wrap'
    }
}));

interface IProps {
    show?: boolean;
}

const AlertError = ({
    show,
    children
}: React.PropsWithChildren<IProps>) => {
    const classes = useStyles();
    return (
        <Fade
            in={show}
            timeout={500}
        >
            <Alert
                classes={{
                    root: classes.root
                }}
                severity={'error'}
            >
                {children}
            </Alert>
        </Fade>
    );
};

export default AlertError;
