import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';

interface IFormButtonProps extends ButtonProps {
    isSubmitting?: boolean;
    onClick?: MouseEventHandler;
}

const FormButton = ({
    isSubmitting,
    onClick,
    children,
    ...otherProps
}: React.PropsWithChildren<IFormButtonProps>) => (
    <Button
        color={'primary'}
        disabled={isSubmitting}
        fullWidth={true}
        size={'large'}
        variant={'contained'}
        {...otherProps}
        onClick={onClick}
    >
        {
            isSubmitting
                ? (
                    <CircularProgress
                        color={'inherit'}
                        size={28}
                    />
                )
                : children
        }
    </Button>
);

export default FormButton;
