import React, { StrictMode } from 'react';
import { BrowserRouter as AppRouter, Route, Switch } from 'react-router-dom';
import { RootPath } from './constants/navigation';
import AuthenticatedRoute from './providers/app-authentication-provider/AuthenticatedRoute';
import AppConfigurationProvider from './providers/app-configuration-provider';
import AppSessionProvider from './providers/app-session-provider';
import AppStoreProvider from './providers/app-store-provider';
import AppThemeProvider from './providers/app-theme-provider';
import Login, { LoginScreenPath } from './screens/login-screen';
import NotFound from './screens/not-found-screen';
import Report, { ReportScreenPath } from './screens/report-screen';

const App = (): JSX.Element => (
    <StrictMode>
        <AppRouter>
            <AppStoreProvider>
                <AppThemeProvider themeType={'light'}>
                    <AppConfigurationProvider>
                        <AppSessionProvider>
                            <Switch>
                                <Route
                                    exact={true}
                                    path={[LoginScreenPath, RootPath]}
                                >
                                    <Login />
                                </Route>
                                <AuthenticatedRoute path={ReportScreenPath}>
                                    <Route
                                        exact={true}
                                        path={[ReportScreenPath, RootPath]}
                                    >
                                        <Report />
                                    </Route>
                                </AuthenticatedRoute>
                                <Route
                                    path={'*'}
                                    status={404}
                                >
                                    <NotFound />
                                </Route>
                            </Switch>
                        </AppSessionProvider>
                    </AppConfigurationProvider>
                </AppThemeProvider>
            </AppStoreProvider>
        </AppRouter>
    </StrictMode>
);

export default App;
