export type IDealership = {
    id: string;
    name: string;
    isDeleted: boolean;
};

const create = (args: Partial<IDealership>): IDealership => {
    if (!args.id) throw new Error('Missing required property id on IDealership');
    if (!args.name) throw new Error('Missing required property name on IDealership');

    return {
        id: args.id,
        name: args.name ?? '',
        isDeleted: args.isDeleted ?? false
    };
};

const Dealership = {
    create: create
};

export default Dealership;
