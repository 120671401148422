import { PowerBIReportResponseModel } from '@dealerpolicy/reports-function-client';
import { Dialog, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { nameof } from '../../libs/utilities/TypeScriptCookbook';

interface IReportFormProps {
    id: string | null;
}

type FormModel = IReportFormProps;

interface ISelectReportDialog {
    options: Array<PowerBIReportResponseModel>;
    isOpen: boolean;
    onClose: () => void;
    onSelect: (internalReportId: string) => void;
}

const SelectReportDialog: React.FC<ISelectReportDialog> = ({ options, isOpen, onClose, onSelect }) => {
    const formMethods = useForm<IReportFormProps>({
        defaultValues: {
            id: null
        }
    });

    return (
        <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={onClose}
            open={isOpen}
        >
            <DialogTitle>
                {'Select the report you wish to view'}
            </DialogTitle>
            <DialogContent>
                <Controller
                    as={(
                        <TextField
                            SelectProps={{
                                displayEmpty: true
                            }}
                            error={Boolean(formMethods.errors?.id?.message)}
                            fullWidth={true}
                            helperText={formMethods.errors?.id?.message}
                            inputProps={{
                                'aria-label': 'id',
                                id: nameof<FormModel>('id')
                            }}
                            label={'Reports'}
                            onClick={formMethods.handleSubmit((data) => onSelect(data.id ?? ''))}
                            select={true}
                            style={{ textAlign: 'left' }}
                            variant={'outlined'}
                        >
                            {
                                (options ?? []).map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    )}
                    control={formMethods.control}
                    defaultValue={''}
                    name={nameof<IReportFormProps>('id')}
                    rules={{
                        required: 'Please select your report'
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SelectReportDialog;
