import { StatusEnum } from '@gjv/redux-slice-factory';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationDuck } from '../../features/authentication';
import { IAuthenticationModelState } from '../../features/authentication/AuthenticationDuck';
import { clearAuthorizationHeaderValue } from '../../libs/jwt/jwt-manager';

const AppSessionProvider: React.FunctionComponent = ({ children }) => {
    const dispatch = useDispatch();
    const authenticationModelState: IAuthenticationModelState = useSelector(AuthenticationDuck.Selectors.selectSliceState);

    useEffect(() => {
        // Ensure the Auth Token managed by the provider is available to data sources via session storage
        // and refresh it before it expires
        if (authenticationModelState.status !== StatusEnum.Requesting && authenticationModelState.status !== StatusEnum.Failed) {
            const token = authenticationModelState.model?.token?.token;
            if (token) {
                dispatch(AuthenticationDuck.Actions.setAuthenticationHeader(authenticationModelState.model, token));

                // schedule a refresh
                const expiresAt = authenticationModelState.model.token?.expiresAt;
                if (expiresAt) {
                    const currentTime = new Date().getTime();
                    const diff = expiresAt - currentTime;
                    const id = window.setTimeout(() => {
                        dispatch(AuthenticationDuck.Actions.refresh(authenticationModelState.model));
                    }, diff - 60000);
                    return () => {
                        window.clearTimeout(id);
                    };
                }
            } else {
                // if no token, ensure session storage is cleared
                clearAuthorizationHeaderValue();
            }
        }

        return () => ({});
    }, [dispatch, authenticationModelState.model, authenticationModelState.status]);

    return <React.Fragment>{children}</React.Fragment>;
};

export default AppSessionProvider;
