import { IAuthenticationToken } from '../../data-sources/authentication-api';
import { IDealership } from '../dealership';

export type IAuthentication = {
    isAuthed: boolean;
    token: IAuthenticationToken | null;
    userId: string | null;
    username: string | null;
    dealershipId: string | null;
    dealershipIds: Array<string>;
    dealerships: Array<IDealership>;
};

const create = (args: Partial<IAuthentication> = {}): IAuthentication => ({
    isAuthed: args.isAuthed ?? false,
    token: args.token ?? null,
    dealershipId: args.dealershipId ?? null,
    dealershipIds: args.dealershipIds ?? [],
    dealerships: args.dealerships ?? [],
    userId: args.userId ?? null,
    username: args.username ?? null
});

const Authentication = {
    create: create
};

export default Authentication;
