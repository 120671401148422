import {
    Action,
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
    MiddlewareAPI,
    Store,
    Reducer
} from 'redux';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';
import AuthenticationReducer from '../features/authentication';
import { IAuthenticationModelState } from '../features/authentication/AuthenticationDuck';
import ConfigurationReducer from '../features/configuration';
import { IConfigurationModelState } from '../features/configuration/ConfigurationDuck';
import ReportReducer from '../features/report';
import { IReportModelState } from '../features/report/ReportDuck';
import ThemeReducer, { IThemeSliceState } from '../features/theme';
import { debugLog, debugLogError, debugLogGroup } from '../libs/utilities';
import { compareObjects } from '../libs/utilities/RamdaCookbook';

export const inDevBuildEnv = (): boolean => process.env.NODE_ENV !== 'production';

export type AppState = {
    Theme: IThemeSliceState;
    Authentication: IAuthenticationModelState;
    Configuration: IConfigurationModelState;
    Report: IReportModelState;
}

const Reducers = combineReducers({
    Theme: ThemeReducer,
    Authentication: AuthenticationReducer,
    Configuration: ConfigurationReducer,
    Report: ReportReducer
});

const rootReducer: Reducer = (state: AppState, action: Action) => {
    if (action.type === 'Authentication/reset') {
        const { Configuration } = state;
        return Reducers({
            Configuration
        } as AppState, action);
    }
    return Reducers(state, action);
};

const logger = ({ getState }: MiddlewareAPI) => (next: Function) => (action: Action) => {
    const previousState = getState();

    const result = next(action);

    debugLogGroup(`dispatched: ${action.type}`, () => {
        const nextState = getState();
        const comparison = compareObjects(previousState, nextState);

        debugLog('action', action);
        debugLog('next state', nextState);
        debugLog('difference', comparison.difference);
    });

    return result;
};

const crashReporter = () => (next: Function) => (action: Action) => {
    try {
        return next(action);
    } catch (err) {
        debugLogError('Caught an exception!', err);
        throw err;
    }
};

const persistedReducer = persistReducer({
    key: 'root',
    storage: sessionStorage,
    debug: inDevBuildEnv()
}, rootReducer);

const windowWithType: any = window;
const composeEnhancers = windowWithType.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose; // for the redux dev tools

interface IConfigureStore {
    Store: Store;
    Persistor: Persistor;
}

const configureStore = (): IConfigureStore => {
    const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, crashReporter, logger));
    const store = createStore(persistedReducer, enhancer);
    const persistor = persistStore(store);

    return {
        Store: store,
        Persistor: persistor
    };
};

export default configureStore;
