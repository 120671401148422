import { StatusEnum } from '@gjv/redux-slice-factory';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/spinner';
import { ConfigurationDuck } from '../../features/configuration';

const AppConfigurationProvider: React.FunctionComponent = ({
    children
}) => {
    const dispatch = useDispatch();

    const configuration = useSelector(ConfigurationDuck.Selectors.selectSliceState);
    const [shouldRequestConfiguration, setShouldRequestConfiguration] = useState(true);

    useEffect(() => {
        if (shouldRequestConfiguration) {
            setShouldRequestConfiguration(false);
            dispatch(ConfigurationDuck.Actions.hydrateConfigurationAsync());
        }
    }, [dispatch, shouldRequestConfiguration]);

    return configuration.status !== StatusEnum.Settled
        ? (
            <Spinner message={'Loading configuration.'} />
        )
        : <React.Fragment>{children}</React.Fragment>;
};

export default AppConfigurationProvider;
