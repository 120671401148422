export interface IUser {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    roles: Array<string>;
    dealershipIds: Array<string>;

}

const create = (args: Partial<IUser>): IUser => {
    if (!args.id) throw new Error('Missing required property id on IUser');
    if (!args.username) throw new Error('Missing required property userName on IUser');

    return {
        id: args.id,
        lastName: args?.lastName ?? '',
        firstName: args?.firstName ?? '',
        username: args.username,
        roles: args?.roles ?? [],
        dealershipIds: args?.dealershipIds ?? []
    };
};

const User = {
    create: create
};

export default User;
