import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AuthenticationDuck, { IAuthenticationModelState } from '../../features/authentication/AuthenticationDuck';
import { LoginScreenPath } from '../../screens/login-screen';

type AuthenticatedRouteProps = {
    path: string;
};
const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children, path }) => {
    const authenticationModelState: IAuthenticationModelState = useSelector(AuthenticationDuck.Selectors.selectSliceState);

    return (
        <React.Fragment>
            {
                authenticationModelState?.model?.isAuthed
                    ? children
                    : (
                        <Redirect
                            exact={true}
                            from={path}
                            to={LoginScreenPath}
                        />
                    )
            }
        </React.Fragment>
    );
};
export default AuthenticatedRoute;
