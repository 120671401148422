import { PowerBIReportsResponseModel } from '@dealerpolicy/reports-function-client';
import { createModelSlice, IModelState, StatusEnum } from '@gjv/redux-slice-factory';
// eslint-disable-next-line import/no-unresolved
import { IEmbedConfigurationBase } from 'embed';
import { models } from 'powerbi-client';
import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import { getPowerBIReportByOrganizationIdAndReportIdAsync, getPowerBIReportsAsync } from '../../data-sources/reports-api';
import Report, { IReport } from '../../models/report/Report';
import { AppState } from '../../store/configureStore';
import { IDuck } from '../types';

export type IReportModelState = IModelState<IReport>;

const { name, reducer, actions, selectors } = createModelSlice<AppState, IReport>({
    name: 'Report',
    selectSliceState: (appState) => appState.Report,
    initialState: {
        model: Report.create()
    }
});

const fetchReports = (token: string) => async (dispatch: Dispatch) => {
    dispatch(actions.setStatus(StatusEnum.Requesting));

    try {
        const reports: PowerBIReportsResponseModel = await getPowerBIReportsAsync(token);
        batch(() => {
            dispatch(actions.update({
                reports: reports.powerBIReports ?? []
            }));
            dispatch(actions.setStatus(StatusEnum.Settled));
            dispatch(actions.setError(null));
        });
    } catch (error) {
        batch(() => {
            dispatch(actions.setStatus(StatusEnum.Failed));
            dispatch(actions.setError(error));
        });
    }
};

const hydrateSelectedEmbeddedConfiguration = (internalReportId: string, reportId: string, uri: string, token: string, dealershipId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.setStatus(StatusEnum.Requesting));

    try {
        const embedTokenResponse = await getPowerBIReportByOrganizationIdAndReportIdAsync(dealershipId, internalReportId, token);
        const embedConfiguration: IEmbedConfigurationBase = {
            type: 'report',
            tokenType: models.TokenType.Embed,
            accessToken: embedTokenResponse.token,
            embedUrl: `${uri}?reportId=${reportId}&groupId=${embedTokenResponse.workspaceId}`
        };
        batch(() => {
            dispatch(actions.update({
                isAuthed: true,
                selectedEmbedConfiguration: embedConfiguration
            }));
            dispatch(actions.setStatus(StatusEnum.Settled));
            dispatch(actions.setError(null));
        });
    } catch (error) {
        batch(() => {
            dispatch(actions.setStatus(StatusEnum.Failed));
            dispatch(actions.setError(error));
        });
    }
};

const allActions = {
    ...actions,
    fetchReports: fetchReports,
    hydrateSelectedEmbeddedConfiguration: hydrateSelectedEmbeddedConfiguration
};

const allSelectors = {
    ...selectors
};

const ReportDuck: IDuck<IReportModelState, typeof allActions, typeof allSelectors> = {
    Name: name,
    Reducer: reducer,
    Actions: allActions,
    Selectors: allSelectors
};

export default ReportDuck;
