import { useHistory } from 'react-router';

/**
 * returns navigation functions, { navigateTo, navigateBack }
 * @returns {{function(string): void, function(): void}} - {navigateTo, navigateBack}
 */
export const useNavigation = () => {
    const history = useHistory();
    const navigateTo = (route: string) => {
        history.push(route);
    };
    const navigateBack = () => {
        history.goBack();
    };
    return {
        navigateTo,
        navigateBack
    };
};

export default useNavigation;
