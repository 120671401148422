import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReportDuck } from '../../features/report';
import { IReportModelState } from '../../features/report/ReportDuck';
import ScreenLayout from '../../layouts/screen-layout';

const ReportScreen = () => {
    const [hasEmbeddedReport, setHasEmbeddedReport] = useState(false);
    const reportModelState: IReportModelState = useSelector(ReportDuck.Selectors.selectSliceState);

    useEffect(() => {
        if (!hasEmbeddedReport) {
            const embedContainer = document.getElementById('report-container');
            window.powerbi.embed(embedContainer as HTMLElement, reportModelState.model.selectedEmbedConfiguration);
            setHasEmbeddedReport(true);
        }
    }, [hasEmbeddedReport, reportModelState.model.selectedEmbedConfiguration]);

    return (
        <ScreenLayout>
            <Container
                maxWidth={'xl'}
                style={{ height: '85vh' }}
            >
                <div
                    id={'report-container'}
                    style={{ height: '100%' }}
                />
            </Container>
        </ScreenLayout>
    );
};

export default ReportScreen;

export const ReportScreenPath = '/report';
