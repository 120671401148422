import { LoggerService, LogLevel } from '@dealerpolicy/core';

const DEFAULT_STRING_VALUE = 'config-value-not-set';

export type IConfiguration = {
    LogLevel: LogLevel | null;
    AuthApiUri: string;
    PowerBIEmbedUri: string;
    MarketplaceApiUri: string;
    ReportsApiUri: string;
    ReturnToMarketplaceUri: string | null;
};

export type IReportConfiguration = {
    id: string;
    roles: string[];
};

const create = (args: Partial<IConfiguration> = {}): IConfiguration => ({
    LogLevel: LoggerService.parseLogLevel(args.LogLevel),
    AuthApiUri: args.AuthApiUri ?? DEFAULT_STRING_VALUE,
    PowerBIEmbedUri: args.PowerBIEmbedUri ?? DEFAULT_STRING_VALUE,
    MarketplaceApiUri: args.MarketplaceApiUri ?? DEFAULT_STRING_VALUE,
    ReportsApiUri: args.ReportsApiUri ?? DEFAULT_STRING_VALUE,
    ReturnToMarketplaceUri: null
});

const Configuration = {
    create: create
};

export default Configuration;
